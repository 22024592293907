<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="12">
        <h1 class="mt-10  ">Nuevas Bases</h1>
        <hr class="">
      </v-col>
      <v-col cols="12">
        <v-tabs
            background-color="primary"
            dark
        >
          <v-tab href="#form">
            Crear Base
          </v-tab>
          <v-tab-item value="form">
            <v-form ref="form" class="mt-10" @submit.prevent="submitForm">
              <v-row class="mb-4">
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Calendario</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Calendario</h1>
                        <trumbowyg
                            v-model="data.calendario"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Calendario"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Especificaciones Técnicas</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Especificaciones Técnicas</h1>
                        <trumbowyg
                            v-model="data.especificaciones_tecnicas"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Especificaciones Técnicas"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Visita guiada</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Visita guiada</h1>
                        <trumbowyg
                            v-model="data.visita_guiada"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Visita guiada"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-col cols="12" class="mt-4">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>

        </v-tabs>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import Overlay from "../../components/Overlay";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload'
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64'

export default {
  name: "Tenders",
  data() {
    return {
      overlay: false,
      configs: {
        advanced: {
          autogrow: true,
          // Adding color plugin button
          btnsAdd: ['foreColor', 'backColor'],
          btnsDef: {
            // Create a new dropdown
            'btnGrp-image': {
              dropdown: ['base64'],
              ico: 'insertImage'
            }
          },
          // Limit toolbar buttons
          btns: [
            ['viewHTML'],
            ['formatting'],
            'btnGrp-semantic',
            ['undo', 'redo'],
            ['superscript', 'subscript'],
            ['btnGrp-image'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['link'],
            ['btnGrp-justify'],
            ['btnGrp-lists'],
            ['horizontalRule'],
            ['foreColor'], ['backColor'],
            ['removeformat'],
            ['fullscreen'],
            [['bold', 'italic'], ['link']]
          ]
        },
      },
      data: {
        tenderId: null,
        formatId: null,
        calendario: "",
        especificaciones_tecnicas: "",
        visita_guiada: "",
        hora_minutos: "",
        fecha_letra: "",
        link_publicacion: ""
      },
    };
  },
  components: {
    Overlay,
    Trumbowyg
  },
  created() {
    this.data.tenderId = this.$route.params.id;
    this.data.formatId = this.$route.params.formatId;

  },
  methods: {
    ...mapActions('bases', ['createBases']),
    async validateRequired() {
      if (!this.data.calendario) {
        this.sweetAlertWarning('Campo Calendario es necesario');
        return false
      }
      if (!this.data.especificaciones_tecnicas) {
        this.sweetAlertWarning('Campo Especificaciones tecnicas es requerido');
        return false
      }
      return true;
    },
    async validateRequiredConstancia() {
      if (!this.data.hora_minutos) {
        this.sweetAlertWarning('Campo Hora y Minutos necesario');
        return false
      }
      if (!this.data.fecha_letra) {
        this.sweetAlertWarning('Campo Fecha en Letra requerido');
        return false
      }
      if (!this.data.link_publicacion) {
        this.sweetAlertWarning('Campo Link de Publicación requerido');
        return false
      }

      return true

    },
    async submitForm() {
      const validateData = await this.validateRequired();

      if (!validateData) {
        return
      }
      this.overlay = true;
      const response = await this.createBases(this.data);
      if (!response.error) {
        this.sweetAlertSuccess('Se creo correctamente')
        await this.$router.push(`/licitaciones_documentos/${this.data.tenderId}`);
      } else {
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
    async submitFormConstancia() {
      const validateData = await this.validateRequiredConstancia();

      if (!validateData) {
        return
      }
      this.overlay = true;
      const response = await this.createBases(this.data);
      if (!response.error) {
        this.sweetAlertSuccess('Se creo correctamente')
        await this.$router.push(`/licitaciones_documentos/${this.data.tenderId}`);
      } else {
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
